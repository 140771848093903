<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card>
              <b-row>
                <b-col lg="6">
                  <h3>ออกเลขรางวัล</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <b-form-group
                    label="หวย"
                    label-for="customDelimiter"
                  >
                    <v-select
                      v-model="LottoHead"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="LottoList"
                      :reduce="ele => ele.value"
                      @input="LottoSubHead = null ,FindSublist(LottoHead), ShowTitle()"
                    />

                  </b-form-group>
                  <b-form-group
                    v-if="LottoSubList"
                    label="ประเภทหวย"
                    label-for="customDelimiter"
                  >
                    <v-select
                      v-model="LottoSubHead"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="LottoSubList"
                      :reduce="ele => ele.value"
                      @input="ShowTitle()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div v-if="(LottoHead && !LottoSubList) || LottoSubHead">
                <b-row v-if="RoundData.StatusResult === false || EditID">
                  <b-col lg="12">
                    งวดประจำวันที่ : {{ RoundData.CloseDateName }}
                  </b-col>
                  <b-col
                    v-if="LottoHead === 1"
                    lg="6"
                  >
                    <b-form-group
                      label="รางวัลที่ 1"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:6"
                        name="Min Character"
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="R1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="6"
                          type="number"
                          placeholder="กรอกรางวัลที่ 1"
                          @input="CalByR1()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'u3') || LottoStatus(LottoHead,LottoSubHead,'t3') || LottoStatus(LottoHead,LottoSubHead,'t2') || LottoStatus(LottoHead,LottoSubHead,'u1')"
                    lg="6"
                  >
                    <b-form-group
                      label="3 ตัวบน"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:3"
                        name="Min Character"
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="U3"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="3"
                          type="number"
                          placeholder="กรอก 3 ตัวบน"
                          @input="CalByU3()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'u2')"
                    lg="6"
                  >
                    <b-form-group
                      label="2 ตัวบน"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:2"
                        name="Min Character"
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="U2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="2"
                          type="number"
                          placeholder="กรอก 2 ตัวบน"
                          @input="CalByU2()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'d2') || LottoStatus(LottoHead,LottoSubHead,'d1')"
                    lg="6"
                  >
                    <b-form-group
                      label="เลข 2 ตัวล่าง"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:2"
                        name=""
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="D2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="2"
                          type="number"
                          placeholder="กรอกเลข 2 ตัวล่าง"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'d3')"
                    lg="6"
                  >
                    <b-form-group
                      label="เลข 3 ตัวหน้า ชุดที่ 1"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:3"
                        name=""
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="F3N1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="3"
                          type="number"
                          placeholder="กรอกเลข 3 ตัวหน้า ชุดที่ 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'d3')"
                    lg="6"
                  >
                    <b-form-group
                      label="เลข 3 ตัวหน้า ชุดที่ 2"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:3"
                        name=""
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="F3N2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="3"
                          type="number"
                          placeholder="กรอกเลข 3 ตัวหน้า ชุดที่ 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'b3')"
                    lg="6"
                  >
                    <b-form-group
                      label="เลข 3 ตัวท้าย ชุดที่ 1"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:3"
                        name=""
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="B3N1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="3"
                          type="number"
                          placeholder="กรอกเลข 3 ตัวท้าย ชุดที่ 1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>

                  <b-col
                    v-if="LottoStatus(LottoHead,LottoSubHead,'b3')"
                    lg="6"
                  >
                    <b-form-group
                      label="เลข 3 ตัวท้าย ชุดที่ 2"
                      label-for="basicInput"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|min:3"
                        name=""
                      >
                        <b-form-input
                          id="basicInput"
                          v-model="B3N2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="3"
                          type="number"
                          placeholder="กรอกเลข 3 ตัวท้าย ชุดที่ 2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>

                  </b-col>

                  <b-col lg="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        v-if="!EditID"
                        variant="primary"
                        @click="validationForm"
                      >
                        ส่งข้อมูล
                      </b-button>
                      <b-button
                        v-if="EditID"
                        variant="warning"
                        class="mr-2"
                        @click="validationForm"
                      >
                        แก้ไขข้อมูล
                      </b-button>
                      <b-button
                        v-if="EditID"
                        variant="secondary"
                        @click="LottoHead = null ,LottoSubHead = null ,ClearItems()"
                      >
                        ยกเลิก
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <div
                  v-else
                  class="alert alert-primary p-1 text-center"
                >
                  <i class="fa fa-folder-open" />
                  ไม่พบรอบที่เปิด
                </div>

              </div>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col lg="12">
          <b-card no-body>
            <b-card-body>
              <div class="p-1">
                <h3>รางวัลที่ออก</h3>
              </div>
            </b-card-body>
            <b-table
              small
              striped
              hover
              responsive
              class="position-relative items-center"
              :per-page="perPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(LottoSubHead)="data">
                {{ data.item.LottoSubHead ? NameLottoSubHead(data.item.LottoHead,data.item.LottoSubHead) : '-' }}
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div
                  v-if="data.item.status === 'waiting_approve'"
                  class="text-nowrap"
                >
                  <span
                    v-if="UserData.role === 'agadmin' || UserData.role === 'admin'"
                    @click="ApproveData(data.item._id,data.item.RoundID)"
                  >
                    <b-avatar
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      v-b-tooltip.hover.v-success.bottom
                      class="mr-1"
                      variant="success"
                      title="ยืนยันเลข"
                    >
                      <feather-icon icon="CheckCircleIcon" />
                    </b-avatar>
                  </span>
                  <span @click="editData(data.item)">
                    <b-avatar
                      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                      v-b-tooltip.hover.v-warning.bottom
                      variant="warning"
                      title="แก้ไข"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-avatar>
                  </span>

                </div>
              </template>
              <template #cell(match)="data">
                <div class="text-nowrap">
                  <span v-if="data.item.match === 1">ยืนยันแล้ว</span>
                </div>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="GetData()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  BFormSelect,
  BOverlay,
  BIconController,
  VBTooltip,
  BAvatar,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import vSelect from 'vue-select'
import 'animate.css'
import {
  required, min, length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BIconController,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      min,
      length,
      ShowRound: false,
      show: true,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่', visible: true },
        {
          key: 'LottoHead',
          label: 'หวย',
          formatter: value => (this.NameLottoHead(value) || '-'),
          visible: true,
        },
        {
          key: 'LottoSubHead',
          label: 'ประเภท',
          visible: true,
        },
        { key: 'RoundData.CloseDateName', label: 'งวดวันที่', visible: true },
        { key: 'U3', label: 'เลขท้าย 3 ตัว(บน)', visible: true },
        { key: 'U2', label: 'เลขท้าย 2 ตัว(บน)', visible: true },
        { key: 'D2', label: 'เลขท้าย 2 ตัว(ล่าง)', visible: true },
        { key: 'R1', label: 'รางวัลที่ 1', visible: true },
        { key: 'F3N1', label: 'เลขหน้า 3 ตัว ที่ 1', visible: true },
        { key: 'F3N2', label: 'เลขหน้า 3 ตัว ที่ 2', visible: true },
        { key: 'B3N1', label: 'เลขท้าย 3 ตัว ที่ 1', visible: true },
        { key: 'B3N2', label: 'เลขท้าย 3 ตัว ที่ 2', visible: true },
        {
          key: 'created_at',
          label: 'ออกผลรางวัลวันที่',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
          visible: true,
        },
        { key: 'addby', label: 'แก้ไขโดย', visible: true },
        { key: 'approveby', label: 'ยืนยันโดย', visible: true },
        { key: 'actions', label: 'เครื่องมือ', visible: true },
      ],
      /* eslint-disable global-require */
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      RoundData: {},
      R1: null, // รางวัลที่ 1 [THG]
      F3N1: null, // เลขหน้า 3 ตัว ที่1 [THG]
      F3N2: null, // เลขหน้า 3 ตัว ที่2 [THG]
      B3N1: null, // เลขท้าย 3 ตัว ที่1 [THG]
      B3N2: null, // เลขท้าย 3 ตัว ที่2 [THG]
      U3: null, // 3 ตัวบน [THG]
      U2: null, // 2 ตัวบน [THG]
      D2: null, // 2 ตัวล่าง [THG]
      EditID: null,

    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  async created() {
    this.LottoList = await this.GetSublist()
    this.interval = setInterval(() => {
      this.GetData()
    }, 30000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    if (this.UserData.role !== 'agadmin' && this.UserData.role !== 'admin') {
      this.fields[14].visible = false
    }

    this.GetData()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      // console.log(this.LottoList)
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      // console.log(IndexHead)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            console.log(this.LottoList[IndexHead].SubList[IndexSub].Data[Val])
            console.log(Val)
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
         this.confirmSumit()
        }
      })
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      this.LottoSubList = this.LottoList[Index].SubList
    },
    GetData() {
      let index = 0
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
      }
      this.$http
        .get('result/showall', { params })
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.onFiltered(ResData.mes)
            ResData.mes.Data.forEach(items => {
              this.getAdmin(items.addby, index, 'addby')
              this.getAdmin(items.approveby, index, 'approveby')
              index += 1
            })
          }
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Data
      this.totalRows = filteredItems.total
    },
    async ShowTitle() {
      this.ClearValue()
      if (this.LottoHead) {
        this.ShowRound = true
        const params = {
          LottoHead: this.LottoHead,
          LottoSubHead: this.LottoSubHead,
        }
        try {
          const { data: ResData } = await this.$http.get(
            'round/ShowByHeadAndSub',
            { params },
          )
          if (ResData.success) {
            this.RoundData = ResData.mes
          }
          this.ShowRound = false
        } catch (e) {
          this.ShowRound = false
          console.log(e)
        }
      }
    },
    async confirmSumit() {
      const result = await this.SwalApprove('เมื่อกดยืนยันแล้วระบบจะทำการบันทึกเลขเพื่อออกรางวัล')
      if (result) {
        const DateRound = moment(this.RoundData.CloseDate).tz('Asia/Bangkok')
        const DateNow = moment().tz('Asia/Bangkok')
        const Sec = DateRound.diff(DateNow, 'seconds')
        const DateRoundText = moment(this.RoundData.CloseDate).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')
        const DateNowText = moment().tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
        if (Sec > 0) {
          this.$swal({
            title: `รอบ ${DateRoundText} ยังไม่ถึงเวลาปิด`,
            text: `กรุณายืนยันการเปลี่ยนเวลาปิดรอบ ${DateNowText}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            showClass: {
              popup: 'animate__animated animate__shakeX',
            },
            buttonsStyling: false,
          }).then(resultCheck => {
            if (resultCheck.value) {
              const CloseDateName = this.CalNameDate()
              const CloseDate = moment().format('YYYY-MM-DD HH:mm:ss')
              const StopBetTime = moment().format('HH:mm:ss')
              this.submit(CloseDate, CloseDateName, StopBetTime)
            }
          })
        } else {
          this.submit()
        }
      }
    },
    submit(CloseDate = null, CloseDateName = null, StopBetTime = null) {
      this.show = true
      const formData = {
        LottoHead: this.LottoHead,
        LottoSubHead: this.LottoSubHead,
        status: 'waiting_approve',
        // eslint-disable-next-line no-underscore-dangle
        RoundID: this.RoundData._id,
        R1: this.R1,
        F3N1: this.F3N1,
        F3N2: this.F3N2,
        B3N1: this.B3N1,
        B3N2: this.B3N2,
        U3: this.U3,
        U2: this.U2,
        D2: this.D2,
        CloseDate,
        CloseDateName,
        StopBetTime,
      }
      if (this.EditID) {
        this.$http
          .post(`result/update/${this.EditID}`, formData)
          .then(() => {
            this.show = false
            this.LottoHead = null
            this.LottoSubHead = null
            this.ClearValue()
            this.GetData()
            this.Success('บันทึกออกเลข สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('result/store', formData)
          .then(() => {
            this.show = false
            this.LottoHead = null
            this.LottoSubHead = null
            this.ClearValue()
            this.GetData()
            this.Success('บันทึกออกเลข สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    editData(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.EditID = item._id
      this.FindSublist(item.LottoHead)
      this.LottoHead = item.LottoHead
      this.LottoSubHead = item.LottoSubHead
      this.RoundData = item.RoundData
      this.R1 = item.R1
      this.F3N1 = item.F3N1
      this.F3N2 = item.F3N2
      this.B3N1 = item.B3N1
      this.B3N2 = item.B3N2
      this.U3 = item.U3
      this.U2 = item.U2
      this.D2 = item.D2
    },
    async ApproveData(ID, RoundID) {
      const Status = await this.SwalApprove('เมื่อกดยืนยันแล้วระบบจะทำการออกรางวัลสามารถตรวจสอบได้ที่หน้าผลการออกรางวัล')
      if (Status) {
        this.show = true
        const obj = {
          ResultID: ID,
          RoundID,
        }
        this.$http
          .post('seamless/result/approve', obj)
          .then(() => {
            this.show = false
            this.LottoHead = null
            this.LottoSubHead = null
            this.ClearValue()
            this.GetData()
            this.Success('บันทึกออกเลข สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
      console.log(Status)
    },
    async getAdmin(addby, index, key) {
      if (addby) {
        const { data: response } = await this.$http
          .get(`admin/show/${addby}`)
        this.items[index][key] = response.name
      }
      return '-'
    },
    ClearValue() {
      this.EditID = null
      this.RoundData = {}
      this.R1 = null
      this.F3N1 = null
      this.F3N2 = null
      this.B3N1 = null
      this.B3N2 = null
      this.U3 = null
      this.U2 = null
      this.D2 = null
    },
    NameLottoHead(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      return this.LottoList[Index].text
    },
    NameLottoSubHead(Head, Sub) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
        if (IndexSub >= 0) {
          return this.LottoList[IndexHead].SubList[IndexSub].text
        }
      }
      return '-'
    },
    CalNameDate(Val) {
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Year = Number(moment(Val).tz('Asia/Bangkok').format('YYYY')) + 543
      const Month = moment(Val).tz('Asia/Bangkok').format('M')
      const Day = moment(Val).tz('Asia/Bangkok').format('DD')

      return `${Day} ${MonthTH[Month]} ${Year}`
    },
    async SwalApprove(mes) {
      const { isConfirmed } = await this.$swal({
        title: 'กรุณายืนยันการออกเลข',
        text: mes,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      })
      return isConfirmed
    },
    CalByR1() {
      if (this.R1 && this.R1.length === 6) {
        const R1Arr = this.R1.split('')
        this.U3 = R1Arr[3] + R1Arr[4] + R1Arr[5]
        this.U2 = R1Arr[4] + R1Arr[5]
      }
    },
    CalByU3() {
      if (this.U3 && this.U3.length === 3) {
        const U3Arr = this.U3.split('')
        if (this.R1 && this.R1.length === 6) {
          const R1Arr = this.R1.split('')
          this.R1 = R1Arr[0] + R1Arr[1] + R1Arr[2] + U3Arr[0] + U3Arr[1] + U3Arr[2]
        }
        this.U2 = U3Arr[1] + U3Arr[2]
      }
    },
    CalByU2() {
      if (this.U2 && this.U2.length === 2) {
        const U2Arr = this.U2.split('')
        if (this.R1 && this.R1.length === 6) {
          const R1Arr = this.R1.split('')
          this.R1 = R1Arr[0] + R1Arr[1] + R1Arr[2] + R1Arr[3] + U2Arr[0] + U2Arr[1]
        }
        if (this.U3 && this.U3.length === 3) {
          const U3Arr = this.U3.split('')
          this.U3 = U3Arr[0] + U2Arr[0] + U2Arr[1]
        }
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
